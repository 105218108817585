import RepositoryRequestMap from '@candybox/repository/request-map.js';
import { validator } from '@candybox/validation/validator.js';
import { singleton } from '@candybox/helpers.js';
import {phoneNumber} from "@app/core/validation";

export default singleton(() => {
    const mapping = new RepositoryRequestMap('/api/institution/institution');

    mapping.validation('store', () => {
        return {
            name: validator()
                .length(0, 300),
            type: validator()
                .required()
                .length(0, 100),
            manager_id: validator()
                .required(),
            chief_name: validator()
                .required()
                .length(0, 250),
            contact_number: validator()
                .required()
                .custom(phoneNumber),
            address: validator()
                .required()
                .length(0, 400),
        }
    });

    return mapping;
});

import Document, {
    Attribute } from "@candybox/structures/document";

class Distributor extends Document
{
    /**
     * Attributes definition
     *
     * @returns {Object}
     */
    attributes() {
        return {
            id: Number,
            name: String,
            token: String,
            edrpou: String,
            manager_full_name: String,
            phone_number: String,
            identifier: String,
            login: String,
            categories: Attribute.array(),
            status: String,
            legal_name: String,
            agreement_id: Number,
            date_of_signature: String,
            positions: Attribute.array(),
            work_schedule: String,
            time_schedule: String,
            website: String,
            email: String,
            comment: String,
            is_agree: Attribute.boolean(false),
        };
    }
}

export default Distributor;
